<template>
    <div class="px-4 text-14 pb-5">
        <form class="p-fluid pt-2" @submit.prevent="">
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Provinsi *
                </label>
                <Dropdown v-model="form.selected_province" :options="provinces" :filter="true" optionLabel="name" placeholder="Pilih Provinsi" :disabled="form.is_submit" />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Nama Kota *
                </label>
                <InputText type="text" placeholder="Nama Kota" v-model="form.name" :disabled="form.is_submit" />
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props: ['data', 'provinces'],
    data() {
        return {
            form: {
                id: null,
                name: '',
                selected_province: '',
                province_id: '',
                province_name: '',
                is_submit: false,
            },
        }
    },
    watch: {
        'form': {
            handler: function() {
                this.form.province_name = this.form.selected_province ? this.form.selected_province['name'] : ''
                this.form.province_id = this.form.selected_province ? this.form.selected_province['id'] : ''
                this.$emit('updated', this.form)
            },
            deep: true,
        },
    },
    mounted() {
        if (this.data) {
            this.form = {...this.form, ...this.data}
            this.form.selected_province = this.provinces.find(obj => obj.id == this.form.province_id);
        }
    },
    methods: {},
}
</script>

<style lang="scss" scoped></style>
